<template>
    <div>
        <el-main>
            <!-- 表单 -->
            <el-form class="el-form-search" label-width="140px">
                <el-form-item label="活动名称：">
                    <el-input size="small" placeholder="请输入活动名称" v-model="searchForm.title"></el-input>
                </el-form-item>
                <el-form-item label="会员姓名：">
                    <el-input size="small" placeholder="请输入会员姓名" v-model="searchForm.jname"></el-input>
                </el-form-item>
                <el-form-item label="联系电话：">
                    <el-input size="small" placeholder="请输入联系电话" v-model="searchForm.mobile"></el-input>
                </el-form-item>
                <el-form-item label="核销状态：">
                    <el-select v-model="searchForm.status" size="small" placeholder="请选择" clearable>
                        <el-option label="全部" value=""></el-option>
                        <el-option label="已核销" :value="1"></el-option>
                        <el-option label="未到场" :value="-1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="数据来源：">
                    <el-select v-model="searchForm.comfrom" size="small" placeholder="请选择" clearable>
                        <el-option label="全部" value=""></el-option>
                        <el-option label="平台上传" :value="1"></el-option>
                        <el-option label="商家申请" :value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="商家名称：">
                    <el-input size="small" placeholder="请输入商家名称" v-model="searchForm.name"></el-input>
                </el-form-item>
                <el-form-item label="报名时间：">
                    <el-date-picker size="small" v-model="time" type="daterange" range-separator="~"
                        start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                </el-form-item>
                <el-form-item label=" " label-width="25px">
                    <el-button type="primary" size="small" @click="search">搜索</el-button>
                    <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
                </el-form-item>
            </el-form>
            <!-- 列表 -->
            <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" :key="key">
                <template slot="empty">
                    <No />
                </template>
                <el-table-column prop="title" label="活动名称" align="center" min-width="160"></el-table-column>
                <el-table-column prop="comfrom" label="数据来源" min-width="100" align="center">
                    <template v-slot="{ row }">{{ row.comfrom == 1 ? '平台上传' : '商家申请' }}</template>
                </el-table-column>
                <el-table-column prop="store_name" label="商家名称" min-width="130" align="center">
                    <template v-slot="{ row }">{{ row.store_name || '--' }}</template>
                </el-table-column>
                <el-table-column prop="register_mobile" label="注册账号" min-width="120" align="center">
                    <template v-slot="{ row }">{{ row.register_mobile || '--' }}</template>
                </el-table-column>
                <el-table-column prop="jname" label="会员姓名" min-width="120" align="center">
                    <template v-slot="{ row }">{{ row.jname || '--' }}</template>
                </el-table-column>
                <el-table-column prop="mobile" label="联系电话" min-width="120" align="center">
                    <template v-slot="{ row }">{{ row.mobile || '--' }}</template>
                </el-table-column>
                <el-table-column prop="remark" label="参与备注" min-width="150" align="center">
                    <template v-slot="{ row }">{{ row.remark || '--' }}</template>
                </el-table-column>
                <el-table-column prop="integral" label="活动积分" min-width="120" align="center">
                    <template v-slot="{ row }">{{ row.integral || '--' }}</template>
                </el-table-column>
                <el-table-column prop="other_integral" label="额外积分" min-width="120" align="center">
                    <template v-slot="{ row }">{{ row.other_integral || '--' }}</template>
                </el-table-column>
                <el-table-column label="报名时间" min-width="150" align="center">
                    <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
                </el-table-column>
                <el-table-column prop="merchant_mobile" label="核销状态" min-width="110" align="center">
                    <template v-slot="{ row }">
                        <div :style="{ 'color': row.jstatus == 1 ? '#409EFF' : row.jstatus == -1 ? '#F56C6C' : '#E6A23C' }">
                            {{
                                row.jstatus == 1 ? '已核销' : row.jstatus == -1 ? '未到场' : '未核销' }}</div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" min-width="160" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text"
                            v-if="scope.row.jstatus == 1 && scope.row.status == 1 && !scope.row.other_integral"
                            @click="handleIntegral(scope.row.id)" size="small">额外积分</el-button>
                        <el-button type="text" v-if="scope.row.gift_reason" @click="handleReason(scope.row.gift_reason)"
                            size="small">额外备注</el-button>
                        <el-button type="text" v-if="scope.row.jstatus == 0" @click="handleVerify(scope.row.id)"
                            size="small">到场核销</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-dialog title="到场核销" :visible.sync="dialogVisible" width="40%">
                <el-form label-position="top" :model="verify_form">
                    <el-form-item label="请选择：" prop="status">
                        <el-radio-group v-model="verify_form.status">
                            <el-radio :label="1">已到场</el-radio>
                            <el-radio :label="-1">未到场</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="备注：">
                        <div>选择已到场，并点击确定，用户活动积分到账</div>
                        <div>选择未到场，并点击确定，则用户后续不会得到此次活动积分</div>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="confirmVerify">确 定</el-button>
                </span>
            </el-dialog>
            <el-dialog title="额外积分" :visible.sync="dialogIntegral" width="40%">
                <el-form :model="integral_form" label-position="top">
                    <el-form-item label="请赠送用户积分：" prop="integral">
                        <el-input placeholder="请赠送用户积分" v-model="integral_form.integral">
                            <template slot="append">分</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="赠送备注：" prop="gift_reason">
                        <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" placeholder="请填写赠送备注"
                            v-model="integral_form.gift_reason">
                        </el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogIntegral = false">取 消</el-button>
                    <el-button type="primary" @click="confirmIntegral">确 定</el-button>
                </span>
            </el-dialog>
            <el-dialog title="额外备注" :visible.sync="dialogReason" width="30%">
                <div>{{ gift_reason }}</div>
            </el-dialog>
            <!-- 翻页 -->
            <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData">
            </Paging>
        </el-main>
    </div>
</template>
  
<script>

import _ from 'lodash';
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';

export default {
    components: {
        Paging,
    },
    data () {
        return {
            list: [],
            total_number: 0,
            searchForm: {
                page: 1,
                rows: 10,
                title: '',
                jname: '',
                mobile: '',
                status: '',
                comfrom: '',
                name: '',
                start_time: '',
                end_time: '',
                activity_id: ''
            },
            time: '',

            verify_form: {
                id: '',
                status: 1
            },
            dialogVisible: false,
            dialogIntegral: false,
            integral_form: {
                id: '',
                integral: '',
                gift_reason: ''
            },
            gift_reason: '',
            dialogReason: false
        };
    },
    created () {
        if (this.$route.query.activity_id) {
            this.searchForm.activity_id = this.$route.query.activity_id
        }
        this.getList();
    },
    methods: {
        search () {
            this.searchForm.page = 1;
            this.getList();
        },
        clearSearch () {
            this.searchForm = {
                page: 1,
                rows: 10,
                title: '',
                jname: '',
                mobile: '',
                status: '',
                comfrom: '',
                name: '',
                start_time: '',
                end_time: '',
                activity_id: ''
            };
            this.getList();
        },
        getDateformat (val) {
            return getDateformat(val);
        },
        updateData (val, status) {
            if (status == 0) {
                this.searchForm.rows = val;
                this.getList();
            } else {
                this.searchForm.page = val;
                this.getList();
            }
        },
        // 获取列表
        getList () {
            let searchForm = { ...this.searchForm }
            if (this.time) {
                searchForm.start_time = this.time[0] / 1000
                searchForm.end_time = this.time[1] / 1000
            }
            this.$axios.post(this.$api.repair.marketing.ActivityJoinList, searchForm).then(res => {
                if (res.code == 0) {
                    this.list = res.result.list;
                    this.total_number = res.result.total;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        handleVerify (id) {
            this.verify_form.id = id
            this.verify_form.status = 1
            this.dialogVisible = true
        },
        confirmVerify () {
            this.$axios.post(this.$api.repair.marketing.ActivityVerify, this.verify_form).then(res => {
                if (res.code == 0) {
                    this.$message.success('核销成功');
                    this.dialogVisible = false
                    this.getList();
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        handleIntegral (id) {
            this.integral_form.id = id
            this.dialogIntegral = true
        },
        confirmIntegral () {
            this.$axios.post(this.$api.repair.marketing.ActivityAddIntegral, this.integral_form).then(res => {
                if (res.code == 0) {
                    this.$message.success('赠送成功');
                    this.dialogIntegral = false
                    this.getList();
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        handleReason (gift_reason) {
            this.gift_reason = gift_reason
            this.dialogReason = true
        }
    },
};
</script>
  
<style lang="less" scoped>
.el-main {
    background: #fff;
}
</style>
  